body {
  color: #1d1e1f;
  background-color: #c9d1d9;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
}

a {
  color: #1d1e1f;
}

a#logo {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
}

a#logo:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 20px;
}

p {
  font-size: 15px;
  line-height: 1.3em;
}

pre, code, textarea {
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
}

main {
  height: 100vh;
  flex-direction: column;
  display: flex;
}

div#app {
  flex-direction: row;
  flex-grow: 1;
  gap: 1px;
  display: flex;
}

pre, textarea {
  color: #c9d1d9;
  white-space: pre-wrap;
  background-color: #0d1117;
  border-width: 0;
  flex: 1;
  margin: 0;
  padding: 0;
  font-size: .9rem;
}

pre {
  overflow: hidden;
}

textarea {
  resize: none;
  border: none;
  outline: none;
  padding: 1em;
  overflow: auto;
}

.inverse {
  color: #1d1e1f;
  background-color: #c9d1d9;
  font-weight: bold;
}

header {
  background-color: #c9d1d9;
  border-bottom: 1px solid #0d1117;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

nav {
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  display: flex;
}

div#support {
  gap: 10px;
  display: flex;
}

div#support img {
  height: 3em;
}

article div#support {
  justify-content: center;
  padding: 2.5em;
}

article div#support img {
  height: 5em;
}

article {
  max-width: 23.7cm;
  margin: auto;
  padding: 10px 10px 2.3cm;
}

article img {
  max-width: 100%;
}

footer {
  width: 100vw;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

div#settings {
  color: #1d1e1f;
  width: 150px;
  text-align: center;
  background-color: #c9d1d9;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 5px;
  padding: 10px 20px;
  font-weight: normal;
  display: flex;
}

/*# sourceMappingURL=index.340c287d.css.map */
