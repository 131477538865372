body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
    helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  color: #1d1e1f;
  background-color: #c9d1d9;
}
a {
  color: #1d1e1f;
}

a#logo {
  font-size: 24px;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 20px;
}

p {
  font-size: 15px;
  line-height: 1.3em;
}

pre,
code,
textarea {
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console,
    monospace;
}

main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

div#app {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 1px;
}

pre,
textarea {
  margin: 0;
  padding: 0;
  flex: 1;
  /* height: 100%; */
  color: #c9d1d9;
  background-color: #0d1117;
  border-width: 0;
  white-space: pre-wrap;
  font-size: 0.9rem;
}
pre {
  overflow: hidden;
}

textarea {
  border: none;
  resize: none;
  outline: none;
  overflow: auto;
  padding: 1em;
}

.inverse {
  color: #1d1e1f;
  background-color: #c9d1d9;
  font-weight: bold;
}

header {
  background-color: #c9d1d9;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  border-bottom: 1px solid #0d1117;
}

nav {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

// support
div#support {
  display: flex;
  gap: 10px;
}

div#support img {
  height: 3em;
}

article {
  div#support {
    padding: 2.5em;
    justify-content: center;
    img {
      height: 5em;
    }
  }
}

article {
  margin: auto;
  padding: 10px;
  max-width: 23.7cm;
  padding-bottom: 2.3cm;
}

article img {
  max-width: 100%;
}

/* in case I want to add some more elements on the bottom right. */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

div#settings {
  font-weight: normal;
  color: #1d1e1f;
  background-color: #c9d1d9;
  padding: 10px 20px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 5px;
  text-align: center;
}
